@import './../../core.scss';

.partners {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    padding: 0 50px 80px;
    position: relative;

    max-width: $desktop;
    margin: 0 auto;

    &__block {
        display: flex;
        justify-content: space-between;
        margin: 30px 0px;
    }
    &__logos {
        flex: 3 1 auto;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        color: $primary-dark;

        margin-top: 64px;
        flex: 1 1 auto;

        max-width: 220px;
        width: 100%;
    }

    &__logo {
        background: transparent;
        margin: 10px 24px 10px 0px;
        height: 150px;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        & img {
            width: inherit;
            height: inherit;
        }
    }
    @include scale_from_to($smallDesktop, $tablet) {
        padding: 50px 30px;
    }
    @include scale_from_to($smallDesktop, $min-size) {
        &__block {
            flex-direction: column;
            align-items: flex-start;
        }
        &__title {
            // margin-left: 10px;
        }
    }
    @include scale_from_to($tablet, $min-size) {
        padding: 50px 15px;
        &__block { align-items: flex-start; }
        &__logos {
            flex-wrap: wrap;
        }
        &__title { font-size: 28px; }
    }
}
