@import './../../core.scss';

.banner {
    padding: 50px 50px 50px;
    text-align: center;

    &__text {
        font-weight: 500;
        font-size: 52px;
        line-height: 68px;
        color: $primary-dark;
        text-shadow: 1px 0 0 #0d0d0d;

        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @include scale_from_to($tablet, $min-size) {
        padding: 30px 15px;
        &__text {
            font-size: 24px;
            line-height: 32px;
        }
    }

}

.blue-banner {
    width: 100%;
    position: relative;
    max-width: $desktop;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @include scale_from_to(1201px, $tablet) {
        // width: 500px;
        margin: 0 auto;
    }

    @media screen and (min-width: 1920px) {
        max-width: 1700px;
    }
    //@include scale_from_to($smallDesktop, $tablet) {
    //    height: 400px;
    //}
}


#bluebannerjs{
    opacity: 0;
}
#eventbannerjs {
    opacity: 1;
}
