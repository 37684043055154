@import "./../../core.scss";

.nav {
  position: sticky;
  top: 0;

  z-index: 20;
  width: 100%;
  max-width: 100%;
  background: #ffc23a;
  height: 90px;

  &__wrap {
    max-width: 1200px;
    width: 100%;
    height: 100px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
  }
  &__container {
    //padding: 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    box-sizing: border-box;
  }

  @media screen and (min-width: 1024px) {
    &__container {
      padding: 10px 60px;
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__left {
    display: flex;
    align-items: center;
    flex: 2 1 auto;
  }
  &__logo {
    margin-right: 1rem;
    height: 23px;
    margin-top: 8px;
    width: 282px;
  }
  &__date {
    font-size: 18px;
    line-height: 23px;
  }
  & .button {
    @media screen and (min-width: 768px) {
      margin-left: 20px;
    }
  }
  &__link {
    font-size: 18px;
    line-height: 23px;
    text-decoration: none;
    color: $primary-dark;
    padding: 10px;

    &::after {
      content: "";
      display: block;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width 0.7s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: $primary-red;
    }
  }
  &__link_button {
    /** see .button box-shadow 6px */
    margin-top: 6px;
  }
  @include scale_from_to($smallDesktop, $tablet) {
    width: 100%;
    //&__container { padding: 0 10px 0 30px; }
    &__left {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
  @include scale_from_to($smallDesktop, $min-size) {
    &__wrap {
      flex-direction: column;
      height: fit-content;
      padding: 40px 0;

      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5);
      border-radius: 10px;

      margin: 10px;
    }

    &__link {
      padding: 15px 5px;
      font-size: 23px;
    }
  }
  @include scale_from_to($tablet, $min-size) {
    position: static;
    &__left,
    &__right {
      flex-direction: column;
      align-items: flex-start;
    }
    .button { margin-top: 20px; width: 340px; min-width: max-content; max-width: 340px; }
  }
}

.menu__icon {
  display: none;
}
.menu__list > li {
  position: relative;
  margin-left: 20px;
  margin-bottom: 0px;
}
.menu__close {
  width: 30px;
  height: 30px;
  display: none;

  @include scale_from_to(1024px, $min-size) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-left: auto;
    margin-right: 10px;
  }
}


@media (min-width: 767px) {
  .menu__list {
    display: flex;
    align-items: center;
  }
  .menu__list > li {
    padding: 10px 0;
  }
}

@media (max-width: 1024px) {
  .nav__link {
    color: $white;
  }
  .nav__right { flex: 0 1 auto; }
  .nav__container {
    align-items: flex-start;
    padding: 16px;
  }
  .menu__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .menu__icon {
    z-index: 5;
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
    margin-top: 10px;
  }
  .menu__icon span,
  .menu__icon::before,
  .menu__icon::after {
    left: 0;
    position: absolute;
    height: 4px;
    width: 100%;
    transition: all 0.3s ease 0s;
    background-color: #fff;
  }
  .menu__icon::before,
  .menu__icon::after {
    content: "";
  }
  .menu__icon::before {
    top: 0;
    background: #b92f29;
  }
  .menu__icon::after {
    bottom: 0;
    background: #b92f29;
  }
  .menu__icon span {
    top: 50%;
    transform: scale(1) translate(0, -50%);
    background: #b92f29;
  }

  .menu__icon._active span {
    transform: scale(0) translate(0, -50%);
  }
  .menu__icon._active::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
  }
  .menu__icon._active::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
  }

  .menu__body {
    position: fixed;
    top: 0;
    z-index: 100;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 100px 0px 30px 0px;
    transition: left 0.3s ease 0s;
    overflow: auto;
  }
  .menu__body._active {
    left: 0;
    width: 100%;
  }
  .menu__list > li {
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding: 30px 0px;
  }
  .menu__list > li:last-child {
    margin-bottom: 0;
  }
  .menu__link {
    font-size: 24px;
  }
}
@include scale_from_to($tablet, $min-size) {
  .menu__body { padding-top: 20px; }
}
