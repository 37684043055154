@import "./mixins.scss";
@import "./variables.scss";
@import "./sizes.scss";
* {
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    scroll-behavior: smooth;
    scroll-padding-top: 70px;
}

html {
    scroll-padding-top: 80px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
    background-color: $primary-yellow;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    //font-family: 'DM Sans', Arial, sans-serif;

    position: relative;
    height: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  
::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 7px;
    -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
  
::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
}
  
::-webkit-scrollbar-corner {
    background-color: transparent;
}

.page-container {

    @media screen and (min-width: 767px) {
        margin: 0 auto;
        max-width: calc(1342px + 15px + 15px);
        padding: 0 15px;
    }
}

.page {
    height: 100%;
    margin-top: 74px;
    @include scale_from_to($smallDesktop, $min-size) { margin-top: 0; overflow-x: hidden; }
    @include scale_from_to(1400px, $min-size) { overflow-x: hidden; }
}

ul {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}

.container {
    max-width: $desktop;
    border: 1px solid #000;
    min-height: 100vh;
    height: 100%;
    margin: 0 auto;
    padding: 0 50px;
}

.promo-video {
    margin: 0 auto;
    height: fit-content;
    width: 100%;
    background: #0d0d0d;

    display: flex;
    align-items: center;
    justify-content: center;

    &__fullscreen {
        right: 0;
        bottom: 0;
        min-height: 796px;
        width: auto;
        height: auto;
        max-width: $desktop;
    }
    @include scale_from_to($tablet, $min-size) {

        &__fullscreen {
            width: inherit;
            min-height: auto;
        }
    }
}

.button {
    color: $primary-yellow;
    background-color: $primary-red;
    text-transform: uppercase;
    white-space: nowrap;

    min-width: 142px;
    min-height: 42px;

    border-radius: 30px;
    border: 2px solid $primary-yellow;
    box-shadow: 6px 6px 0 #000000;

    padding: 8px 20px;

    font-style: 400;

    z-index: 12;
    font-size: 22px;
    line-height: 28px;
    font-family: 'DM Sans', serif;
    cursor: pointer;

    &:disabled,
    &[disabled] {
        background:gray;
        &:hover {
            background-color: gray;
            color: $primary-yellow;
            font-style: normal;
        }
    }

    @media (min-width: 768px) {
        &:hover {
            background: $primary-blue;
            font-style: italic;
            color: $primary-dark;
        }
    }

    &:active {
        background: $primary-red;
        font-style: italic;
        color: $white;
    }

    @include scale_from_to($tablet, $min-size) {
        width: -webkit-fill-available;
        font-size: 28px;
        line-height: 36px;
    }
}

.button.button--primary {
    background: $primary-red;
    font-style: italic;
    color: $white;
}

.comic-text {
    font-size: 66px;
    line-height: 82px;
    font-family: 'Bangers', cursive;
    letter-spacing: 2px;
    text-shadow:0px 1px 0 rgb(13,13,13),0px -1px 0 rgb(13,13,13),1px 0px 0 rgb(13,13,13),-1px 0px 0 rgb(13,13,13),1px 1px 0 rgb(13,13,13),1px -1px 0 rgb(13,13,13),-1px 1px 0 rgb(13,13,13),-1px -1px 0 rgb(13,13,13),0px 2px 0 rgb(13,13,13),0px -2px 0 rgb(13,13,13),2px 0px 0 rgb(13,13,13),-2px 0px 0 rgb(13,13,13),2px 2px 0 rgb(13,13,13),2px -2px 0 rgb(13,13,13),-2px 2px 0 rgb(13,13,13),-2px -2px 0 rgb(13,13,13),3px 3px 1px rgba(0,0,0,1);
    color: $primary-yellow;

    position: relative;
    z-index: 2;
    &--header::before {
        content: "";
        background-image: url("./../images/header-red-spot.svg");
        background-repeat: no-repeat;
        width: 532px;
        height: 130px;
        display: block;

        position: absolute;
        z-index: -1;
        top: -30px;
        left: -20px;
    }

    &--banner::before {
        content: "";
        background-image: url("./../images/banner-red-spot.svg");
        background-repeat: no-repeat;
        width: 400px;
        height: 165px;
        display: block;

        position: absolute;
        z-index: -1;
        top: -50px;
        left: -20px;
    }
    &--speakers::before {
        content: "";
        background-image: url("./../images/speakers-red-spot.svg");
        background-repeat: no-repeat;
        width: 713px;
        height: 147px;
        display: block;

        position: absolute;
        z-index: -1;
        top: -50px;
        left: -20px;
    }
    @include scale_from_to($smallDesktop, $tablet) {
        &--speakers::before {
            left: -30px;
        }
    }
    @include scale_from_to($tablet, $min-size) {
        letter-spacing: 0px;
        font-size: 40px;
        line-height: 45px;
        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
        &--header::before {
            top: 15%;
            left: 0%;
            background-image: url("./../images/Vector-6.svg");
            width: 301px;
            height: 67px;
        }
        &--banner::before {
            top: -10px;
            width: 164px;
            height: 67px;
            background-image: url("./../images/banner-red-spot-small.svg");
        }
        &--speakers { margin-bottom: 10px;
            &::before {
                background-image: url("./../images/speakers-red-spot-small.svg");
                top: -30px;
                left: 0px;
            }
        }
    }
}

.high-speakers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
}


