@import "./../../core.scss";

.buy-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;

    .comic-text {
        text-align: center;
        margin: 3rem 0 1rem 0;
    }

    &__wrap {
        width: 100%;
        background: #4fade4;
        border-top: 14px solid black;
        border-bottom: 14px solid black;
        margin-top: 80px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        background: #1B1B1B;
        color: #ffc23a;
        max-width: 634px;
        width: 100%;
        box-shadow: 12px 9px 7px rgba(0, 0, 0, 0.18);
        border-radius: 23px;

        padding: 30px;
    }
    &__bg {
        // background-image: url("./../../assets/images/bg.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        height: 630px;
        width: 100%;
    }
    &__info {
        font-size: 42px;
        line-height: 55px;
        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #0D0D0D;

        margin: 47px auto 24px;
    }
    &__title {
        font-size: remConverter(98);
        font-family: Bangers;
        line-height: 104px;
        text-align: center;
        letter-spacing: 0.09em;
        margin-top: 74px;

        color: #4FADE4;
        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
        -webkit-text-stroke: 1px #4fade4;

        position: relative;

        &::after {
            content: "Baltic HoneyBadger 2022";
            text-shadow: 12px 8px 0 #0d0d0d, -3px -3px 0 #0d0d0d, 3px -3px #0d0d0d, -3px 3px #0d0d0d, 0px -3px #0d0d0d, -3px 0px #0d0d0d, 0px 3px #0d0d0d, 3px 0px #0d0d0d;

            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &__label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
        padding: 20px 0;

        font-size: 14px;
        line-height: 16px;
    }
    & .subscribe {
        background: $primary-blue;
        &__input {
            color: $primary-dark;
            border-bottom: 2px solid $primary-dark;
            background: $primary-blue;
            &:-webkit-autofill,
            &:-webkit-autofill:active,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-text-fill-color: #0d0d0d;
                -webkit-box-shadow: 0 0 0px 1000px $primary-blue inset;
                transition: background-color 5000s ease-in-out 0s;
            }

            &:active,
            &:focus { background: $primary-blue; }

            &::placeholder { color: $primary-dark; }
        }
        &__notification { color: $primary-dark; }
        &__sucess { color: $primary-dark; }
        .subscribe__error { color: $primary-dark; }
        .subscribe__wrap { button {max-width: 142px;} }
    }

    @include scale_from_to($desktop, $smallDesktop) {
        &__title { font-size: 82px; line-height: 87px; }
    }

    @include scale_from_to($smallDesktop, $tablet) {
        &__title { font-size: 78px; line-height: 83px; }
    }

    @include scale_from_to($tablet, $min-size) {
        margin-top: 40px;
        &__title{
            font-size: 42px;
            line-height: 45px;
            letter-spacing: 0.03em;
            margin-top: 40px;
            &::after {
                text-shadow: 1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
            }
        }
        & .subscribe {
            padding-top: 0;
            .subscribe__wrap { button { max-width: 100%; } }
        }
        &__wrap {
            margin-top: 40px;
            overflow-x: hidden;
            & .comic-text {
                font-size: 36px;
                line-height: 38px;
                margin: 40px 15px;
            }
            & .button {
                font-size: 18px;
                line-height: 23px;
            }
        }
    }
}
