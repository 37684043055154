@import "./../../core.scss";

.persons {
  padding: 80px 0;
  max-width: $desktop;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @include scale_from_to($smallDesktop, $tablet) {
    padding: 80px 30px;
  }

  &__image {
    width: 20px;
    height: 24px;
    display: block;
    margin-left: 5px;
    fill: #0d0d0d;
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    height: min-content;
    text-decoration: none;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  &__link {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 23px;
    text-decoration: none;
    color: $primary-dark;

    &::after {
      content: "";
      display: block;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width 0.7s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: $primary-red;
    }
  }
  .person {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    &__link {
      position: relative;
    }

    &__remotely {
      background-color: #0D0D0D;
      color: #FFC239;
      position: absolute;
      width: 100%;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      padding: 6px 0;
      bottom: 2px;
    }

    &__image {
      border: 3px solid #000000;
      width: 210px;
      height: 210px;

      background: transparent;
      @media screen and (min-width: 1920px) {
        width: 250px;
        height: 250px;
      }
    }
    & .block__text, .block__title {
      max-width: 225px;
    }
  }
  @include scale_from_to($smallDesktop, $tablet) {
    padding: 80px 30px;
    &__wrap {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include scale_from_to($tablet, $min-size) {
    padding: 15px 0px;
    margin-bottom: 80px;
    &__wrap { grid-template-columns: 1fr; align-items: center; justify-items: center; }
    .banner {
      &-wrap {
        flex-direction: column;
      }
    }
    &__wrap {
      &::after {
        top: -137px;
      }
    }
    .person {
      align-items: center;
      & .block__title,
      & .block__text {
        text-align: center;
      }
    }
  }
}
