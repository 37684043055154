.swiper-block {
  padding: 0 0 80px 0;

  max-width: $desktop;
  margin: 0 auto;
  margin-right: 0;

  .swiper {
    padding-bottom: 40px;
    width: 100%;
    height: 100%;

    @media (min-width: 769px) {
      --swiper-width: calc(calc(calc(100vw - 100%) / 2) + 100%);

      min-width: var(--swiper-width);
    }
  }

  .swiper__video {
    height: fit-content;
    margin: 0 auto;
  }

  .swiper__description {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-align: left;

    margin-top: 12px;
    color: $primary-dark;
    padding-bottom: 40px;
  }

  .video-responsive {
    height: fit-content;
    max-height: 455px;
    margin: 0 auto;
  }

  .swiper-video-placeholder {
    position: relative;
    width: 850px;
    height: 455px;
  }

  .swiper-video-placeholder::before {
    transform: translate(-50%, -50%);
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    content: '';
    width: 68px;
    height: 48px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 721' xml:space='preserve'%3E%3Cpath fill='%23FFF' d='m407 493 276-143-276-144v287z'/%3E%3Cpath opacity='.12' fill='%23420000' d='m407 206 242 161.6 34-17.6-276-144z'/%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='512.5' y1='719.7' x2='512.5' y2='1.2' gradientTransform='matrix(1 0 0 -1 0 721)'%3E%3Cstop offset='0' stop-color='%23e52d27'/%3E%3Cstop offset='1' stop-color='%23bf171d'/%3E%3C/linearGradient%3E%3Cpath fill='url(%23a)' d='M1013 156.3s-10-70.4-40.6-101.4C933.6 14.2 890 14 870.1 11.6 727.1 1.3 512.7 1.3 512.7 1.3h-.4s-214.4 0-357.4 10.3C135 14 91.4 14.2 52.6 54.9 22 85.9 12 156.3 12 156.3S1.8 238.9 1.8 321.6v77.5C1.8 481.8 12 564.4 12 564.4s10 70.4 40.6 101.4c38.9 40.7 89.9 39.4 112.6 43.7 81.7 7.8 347.3 10.3 347.3 10.3s214.6-.3 357.6-10.7c20-2.4 63.5-2.6 102.3-43.3 30.6-31 40.6-101.4 40.6-101.4s10.2-82.7 10.2-165.3v-77.5c0-82.7-10.2-165.3-10.2-165.3zM407 493V206l276 144-276 143z'/%3E%3C/svg%3E");      background-size: 68px 48px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;

    align-items: flex-start;
    flex-direction: column;

    &:last-child {
      margin-right: 20px;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: fit-content;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 48px;
    top: 90%;
    background-repeat: no-repeat;
    margin-top: 10px;
  }

  .swiper-button-prev {
    background-image: url("./../images/arrow-active-left.svg") !important;
    left: calc(50% - 48px - 24px);
    position: absolute;
  }

  .swiper-button-next {
    background-image: url("./../images/arrow-active-right.svg") !important;
    left: 50%;
    position: absolute;

  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "";
  }

  @include scale_from_to($tablet, $min-size) {
    padding: 0 0 50px;

    .swiper__description {
      font-size: 22px;
      padding: 0 15px;
    }

    .swiper {
      padding-bottom: 0;
    }

    .swiper-slide {
      margin-bottom: 50px;
    }

    .swiper-video {
      width: 100%;
      min-width: 100vw;
    }
  }

  @media screen and (min-width: 1600px) {
    //max-width: 1500px;
    margin: 0 auto;
    margin-right: 0;
  }
}

.article-swiper {
  padding: 0 50px;
  max-width: $desktop;
  margin: 0 auto;

  .block {
    width: 100%;
    margin: 0;

    @include scale_from_to(1024px, $tablet) {
      display: flex;
      align-items: center;
      padding-bottom: 40px;
    }

    @media screen and (min-width: 1024px) {
      margin: 0 10px;
    }
  }

  @include scale_from_to(2000px, 1700px) {
    .block__image {
      width: 370px;
    }
  }

  @include scale_from_to(1300px, 1024px) {
    padding: 0 30px;

    .block__image {
      width: 100%;
      height: 248px;
    }
  }

  @include scale_from_to($tablet, $min-size) {
    padding: 50px 0;
  }
}
