.bitcoin-week {
    font-family: 'DM Sans' !important;
    &.page {
        margin-top: 18px;
    }

    &__header {
        padding: 0 20px;
    }

        &__title {
            font-family: Bangers, Arial, sans-serif;
        }

    &__container {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 20px;
        .multi-toggle-container {
            max-width: 812px;
            height: 42px;
            margin: 42px auto 52px;
        }

        .select {
            .select-option, .select-options.visible, .select-options {
                min-width: 320px;
            }
        }

        .days-blocks {
            margin-bottom: 50px;
            color: white;
            &:last-of-type {
                margin-bottom: 0;
            }

            &__header {
                text-align: center;
                margin-bottom: 8px;
                &__title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 42px;
                    color: #0D0D0D;
                    margin-bottom: 8px;
                }

                &__subtitle {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 23px;
                    color: #0D0D0D;
                }
            }

            &__block {
                border: 2px solid #0D0D0D;
                border-radius: 25px;
                max-width: 1344px;
                margin: 0 auto;


                &__buttons {
                    padding: 0 10px;
                }

                &__info {
                    background-color: #FFFFFF;
                    border-bottom-left-radius: 21px;
                    border-bottom-right-radius: 21px;
                    color: #0D0D0D;
                    padding: 20px 0 28px;

                    &__columns {
                        padding: 0 20px;
                        display: flex;
                        column-gap: 80px;
                        flex-wrap: wrap;
                        &__column {
                            padding-left: 32px;
                        }
                        .days-blocks__block__info__content {
                            display: list-item;
                        }
                    }

                    &__title {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 23px;
                        margin-bottom: 8px;
                        padding: 0 20px;
                        &.topic {
                            margin-top: 28px;
                        }
                    }

                    &__content-big {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 23px;
                        padding: 0 20px;
                        &.listItem {
                            display: list-item;
                        }
                        &.no-padding {
                            padding: 0;
                        }
                        &.margin-sm {
                            margin-bottom: 6px;
                        }
                        &.margin-md {
                            margin-bottom: 12px;
                        }
                        a {
                            text-decoration: underline;
                            color: #0D0D0D;
                            margin-right: 4px;
                        }
                    }

                    &__content {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 23px;
                        margin-bottom: 8px;
                        padding: 0 20px;
                        &.no-padding {
                            padding: 0;
                        }
                    }

                    &__topics {
                        padding: 0 20px 0 50px;
                    }

                    .button {
                        border: 1px solid #FFC239;
                        max-width: 310px;
                        margin: 28px auto 0;
                        display: block;
                        a {
                            color: #FFC239;
                            text-decoration: none;
                        }
                        &__black {
                            background-color: #0D0D0D;
                            &:hover {
                                color: #FFC239;
                            }
                        }
                        &__second{
                            margin-top: 16px;
                        }
                        &__red {
                            text-decoration: underline;
                        }
                    }

                    &__content-img {
                        margin-bottom: 20px;
                        padding: 0 20px;
                        width: 112px;
                    }

                    &__line {
                        border-top: 2px solid #0D0D0D;
                        margin: 28px 0;
                    }
                }

                &__head {
                    padding: 24px 20px 10px 20px;
                    border-top-left-radius: 21px;
                    border-top-right-radius: 21px;
                    border-bottom: 2px solid #0D0D0D;;
                    position: relative;
                    &__title {
                        font-family: Bangers, Arial, sans-serif;
                        font-size: 28px;
                        line-height: 30px;
                        color: #FFC239;
                        text-shadow: 2px 4px 0px #000000;
                        font-weight: 400;
                        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0 -1px #0d0d0d, -1px 0 #0d0d0d, 0 1px #0d0d0d, 1px 0 #0d0d0d;
                    }

                    &__subtitle_block {
                        margin: 8px 0 0;
                        &__item {
                            margin-bottom: 8px;
                            font-size: 18px;
                            line-height: 23px;
                            & a {
                                color: white;
                            }
                            &.diamond {
                                &::before {
                                    content: '';
                                    background: url('./../../assets/images/diamond.svg');
                                    margin-right: 4px;
                                    width: 24px;
                                    height: 24px;
                                    display: inline-block;
                                    vertical-align: sub;
                                }
                            }
                            &.clock {
                                &::before {
                                    content: '';
                                    background: url('./../../assets/images/clock.svg');
                                    margin-right: 4px;
                                    width: 24px;
                                    height: 24px;
                                    display: inline-block;
                                    vertical-align: sub;

                                }
                            }
                            &.ticket {
                                &::before {
                                    content: '';
                                    background: url('./../../assets/images/ticket.svg');
                                    margin-right: 4px;
                                    width: 24px;
                                    height: 24px;
                                    display: inline-block;
                                    vertical-align: sub;
                                }
                            }
                            &.location {
                                span {
                                    padding-left: 28px;
                                }
                                &::before {
                                    content: '';
                                    background: url('./../../assets/images/location.svg');
                                    margin-right: 4px;
                                    width: 24px;
                                    height: 24px;
                                    display: inline-block;
                                    vertical-align: sub;
                                }
                            }

                            &.white {
                                color: #ffffff;
                            }
                        }
                    }

                    .bottom-illustration {
                        position: absolute;
                        top: 60px;
                        right: 20px;
                        bottom: unset;
                        left: unset;
                    }
                }
                .blue {
                    background-color: #4FADE4;
                }
                .lightgreen {
                    background-color: #4FD2C5;
                }
                .black {
                    background-color: #0D0D0D;
                }
                .green {
                    background-color: #1E7A44; 
                }
                .red {
                    background-color: #B92F29;
                }
            }
        }
    }


    &__title {
        text-align: center;
        width: fit-content;
        margin: 0 auto;
        font-weight: 400;
        font-size: 42px;
        line-height: 45px;
        color: #4fade4;
        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d,
            -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d,
            1px 0px #0d0d0d;
        -webkit-text-stroke: 1px #4fade4;
        position: relative;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #0d0d0d;
        margin: 14px auto 27px;
    }

    &__honeybadger {
        background-image: url('./../../assets/images/bg-mobile.png');
        height: 300px;
        margin-top: 0;

        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media screen and (min-width: 768px) {
        &.page {
            margin-top: 46px;
        }

        &__header {
            padding: 0;
        }

        &__title {
            font-size: 66px;
            letter-spacing: 2px;
            line-height: 70px;
            margin-top: 0;

            &::after {
                content: "Riga Bitcoin Week 2022";
                text-shadow: 1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d,
                0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;

                position: absolute;
                left: 0;
                top: 0;
                width: fit-content;
            }
        }

        &__subtitle {
            font-size: 20px;
            line-height: 26px;
            margin-top: 12px;
        }

        &__footer {
            height: 520px;
            position: relative;
        }

        &__honeybadger {
            background: url('./../../assets/images/bg-1920.jpg') no-repeat center center;
            background-size: cover;
            height: 630px;
            position: absolute;
            z-index: -1;
            bottom: 20px;
        }

        &__container {
            padding: 0 28px;

            .days-blocks {
                margin-bottom: 90px;

                &__header {
                    margin-bottom: 16px;
                    border-bottom: none;

                    &__title {
                        font-size: 46px;
                        line-height: 60px;
                        margin-bottom: 16px;
                    }

                    &__subtitle {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                &__block {
                    display: grid;
                    grid-template-columns: 308px 9fr;
                    border: 2px solid #0D0D0D;
                    border-radius: 25px;
                    max-width: 1344px;
                    margin: 0 auto;

                    &__head {
                        border-top-left-radius: 21px;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: 21px;
                        border-right: 2px solid #0D0D0D;
                        border-bottom: none;
                        padding: 38px 0 14px 38px;

                        &__title {
                            font-size: 38px;
                            line-height: 40px;
                            &.small-title {
                                font-size: 32px;
                                line-height: 34px;
                            }
                        }

                        &__subtitle_block {
                            margin: 8px 0 26px;
                            &.no-margin {
                                margin: 4px 0 26px;
                                &__item {
                                    margin-bottom: 2px;
                                }
                            }
                        }

                        .button {
                            border: 1px solid #FFC239;
                            width: 216px;
                            a {
                                color: #FFC239;
                                text-decoration: none;
                            }
                            &__black {
                                background-color: #0D0D0D;
                                &:hover {
                                    color: #FFC239;
                                }
                            }
                            &__second{
                                margin: 16px 0 0;
                            }
                        }

                        .bottom-illustration {
                            position: absolute;
                            bottom: 27px;
                            left: 38px;
                            top: unset;
                            right: unset;
                        }
                    }

                    &__info {
                        border-top-right-radius: 21px;
                        border-bottom-right-radius: 21px;
                        border-bottom-left-radius: 0;
                        padding: 38px 0 42px;

                        &__columns {
                            padding: 0 38px;
                            margin-bottom: 20px;
                            &__column {
                                padding-left: 48px;
                            }
                        }

                        &__topics {
                            padding: 0 0 0 80px;
                        }

                        &__title {
                            font-weight: 700;
                            font-size: 26px;
                            line-height: 34px;
                            padding: 0 38px;
                            margin-bottom: 12px;
                            &.topic {
                                margin-top: 32px;
                            }
                        }

                        &__content-big {
                            font-weight: 400;
                            font-size: 26px;
                            line-height: 34px;
                            padding: 0 38px;
                            &.margin-md {
                                margin-bottom: 12px;
                            }
                            &.margin-sm {
                                margin-bottom: 6px;
                            }
                        }

                        &__content {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 23px;
                            padding: 0 38px;
                        }

                        &__content-img {
                            margin-top: 40px;
                            margin-bottom: 0;
                            width: inherit;
                            padding: 0 38px;
                        }

                        &__line {
                            margin: 38px 0 34px;
                        }
                    }
                }
            }
        }
    }
}
