@import "./../../core.scss";

.subscribe {
    margin: -10px auto 0;
    padding: 64px 20px 50px;
    background: $primary-dark;
    overflow-x: hidden;

    &__fieldset {
        border: none;
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .button {
        padding: 8px 20px;
    }

    &__title {
        font-weight: 500;
        font-size: 52px;
        line-height: 68px;
        margin: 10px 0;
        color: $primary-yellow;

        text-transform: uppercase;
        text-align: center;

        text-shadow: 0 4px 0 #0d0d0d, 0px -1px 0 #0d0d0d, 4px 0px 0 #0d0d0d, -1px 0px 0 #0d0d0d, 4px 4px 0 #0d0d0d, 1px -1px 0 #0d0d0d, -1px 1px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 0px 6px 0 #0d0d0d, 0px -2px 0 #0d0d0d, 2px 0px 0 #0d0d0d, -2px 0px 0 #0d0d0d, 6px 6px 0 #0d0d0d, 6px -2px 0 #0d0d0d, -2px 2px 0 #0d0d0d, -2px -2px 0 #0d0d0d, 3px 3px 1px black;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;

        padding: 10px;
    }

    &__input {
        max-width: 375px;
        min-width: 320px;
        height: 50px;

        border: none;
        outline: none;

        border-bottom: 2px solid $primary-yellow;
        color: $primary-yellow;

        font-size: 22px;
        line-height: 29px;
        background: $primary-dark;

        &:active,
        &:focus { background: $primary-dark; }

        &:-webkit-autofill,
        &:-webkit-autofill:active,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: #FFC239;
            -webkit-box-shadow: 0 0 0 1000px #0d0d0d inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        &::placeholder { color: rgba(255, 194, 57, .6); }
    }

    &__notification {
        color: $primary-yellow;
        font-size: 22px;
        line-height: 29px;
        font-weight: 400;
        margin-top: 20px;
        min-height: 30px;
    }

    &__success {
        color: $primary-yellow;
        font-size: 22px;
        line-height: 29px;
        text-align: center;
    }

    &__error-field {
        visibility: visible;
        line-height: 29px;
        height: 31px;
        margin-top: 10px;
    }

    &__error {
        color: red;
        font-size: 22px;
        font-weight: 400;
    }

    @include scale_from_to(1024px, $tablet) {
        &__fieldset {
            width: min-content;
            margin: 0 auto;
        }
    }

    @include scale_from_to($tablet, $min-size) {
        padding: 64px 15px 50px;

        &__wrap {
            flex-direction: column;
            width: fit-content;
            padding: 0;
            margin: 0 auto;
        }
        &__input { font-size: 18px; line-height: 23px; }
        .button {
            width: -webkit-fill-available;
            margin-top: 1rem;
            font-size: 18px;
        }
        &__fieldset {
            width: 100%;
            margin: 0 auto;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__title {
            font-size: 28px;
            line-height: 36px;
            text-align: left;
        }
    }
}

