$primary-yellow: #FFC239;
$primary-dark: #0D0D0D;
$secondary-dark: #151515;
$third-dark: #181818;
$fourth-dark: #1B1C1D;
$black: #000000;
$primary-red: #B92F29;
$primary-blue: #4FADE4;
$primary-grey: #696969;
$white: #ffffff;
$primary-green: #10A427;
