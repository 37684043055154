@mixin flex($direction, $wrap, $justify, $align) {
  display: flex;
  flex-flow: $direction $wrap;
  justify-content: $justify;
  align-items: $align;
}

@mixin scale_from_to($from, $to) {
  @media screen and (max-width: $from) and (min-width: $to) {
    @content;
  }
}

@function remConverter($pixelSize) {
  $result: $pixelSize / 16;
  @return $result + rem;
}
