@import "./../../core.scss";

.agenda {
    &__title {
        font-family: Bangers, Arial, sans-serif;
        text-align: center;
        width: fit-content;
        margin: 10px auto 0;
        font-weight: 400;
        font-size: 42px;
        line-height: 45px;
        color: #4fade4;
        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d,
            -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d,
            1px 0px #0d0d0d;
        -webkit-text-stroke: 1px #4fade4;
        position: relative;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #0d0d0d;
        margin: 20px auto 36px;
        padding: 0 15px;
    }

    &__container {
        .multi-toggle-container {
            max-width: 290px !important;
            height: 42px;
            margin: 42px auto 52px;
            &.fixed {
                top: 100px;
            }
        }

        .select {
            margin-bottom: 30px;
            .select-option, .select-options.visible, .select-options {
                min-width: 320px;
            }
        }

        .separator {
            width: 200px;
            display: flex;
            flex-basis: 100%;
            margin: 50px auto;
        }

        .activeStage {
            display: unset;
        }

        .disableStage {
            display: none;
        }

        .agenda-stage {
            &__header {
                text-align: center;
                margin-bottom: 14px;
                &__title {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 42px;
                    color: #0D0D0D;
                    margin-bottom: 18px;
                }

                &__subtitle {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 23px;
                    color: #0D0D0D;
                }
            }

            &__block {
                max-width: 350px;
                margin: 0 auto;
                border: 2px solid #0D0D0D;
                border-radius: 20px;

                &__items {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 2px solid #0D0D0D;
                    &:last-of-type { 
                        border-bottom: none;
                    }
                    &__item {
                        &__header {
                            width: 100%;
                            padding: 32px 16px 16px;
                            border-bottom: 1px solid #0D0D0D;
                            position: relative;
                            
                            &.first {
                              border-top-left-radius: 18px;  
                              border-top-right-radius: 18px;  
                              border-right: none;
                            }

                            &.last {
                              border-bottom-left-radius: 18px;    
                            }

                            &.last.full {
                              border-bottom-right-radius: 18px;    
                            }

                            &.full {
                                width: 100%;
                                text-align: left;
                                border: none;
                            }

                            &__text {
                                font-family: Bangers, Arial, sans-serif;
                                font-weight: 400;
                                font-size: 28px;
                                line-height: 30px;
                                color: #F0B736;
                                text-shadow: 1px -1px 0px #000, -1px 1px 0px #000, -1px -1px 0px #000, 3px 3px 0px #000;
                            }

                            &__button {
                                display: block;
                                padding: 6px 12px;
                                background: #FFC239;
                                border: 1px solid #0D0D0D;
                                border-radius: 20px;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 16px;
                                text-transform: uppercase;
                                color: #0D0D0D;
                                position: absolute;
                                bottom: 15px;
                                left: 85px;
                            }

                            &.blue {
                                background-color: #4FADE4;
                            }
                            &.green {
                                background-color: #1DBC84;
                            }
                            &.black {
                                background-color: #0D0D0D;
                            }
                            &.red {
                                background-color: #B92F29;
                            }
                        }

                        &__text {
                            width: 100%;
                            background-color: #ffffff;
                            padding: 16px 16px 24px;
                            &:last-of-type {
                                padding-top: 0;
                            }
                            &__title {
                                font-family: 'DM Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 23px;
                                color: #0D0D0D;
                                & a {
                                    // text-decoration: none;
                                    color: #0D0D0D;
                                }
                            }
                            &__text {
                                margin-top: 6px;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 21px;
                                color: #0D0D0D;
                            }

                            &.first {
                                border-top-right-radius: 0;  
                            }
  
                            &.last {
                                border-bottom-right-radius: 18px;  
                                border-bottom-left-radius: 18px;    
                            }
                        }
                    }
                }
            }
        }
    }


    @media screen and (min-width: 768px) {
        &__title {
            font-size: 66px;
            letter-spacing: 2px;
            line-height: 70px;
            margin-top: 0;

            &::after {
                content: "Conference agenda";
                text-shadow: 1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d,
                0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;

                position: absolute;
                left: 0;
                top: 0;
                width: fit-content;
            }
        }

        &__subtitle {
            margin-top: 12px;
        }


        &__container {
            padding: 0 28px;

            .agenda-stage {
                margin-bottom: 90px;

                &__header {
                    margin-bottom: 16px;
                    border-bottom: none;

                    &__title {
                        font-size: 46px;
                        line-height: 60px;
                        margin-bottom: 16px;
                    }

                    &__subtitle {
                        font-size: 20px;
                        line-height: 26px;
                    }
                }

                &__block {
                    max-width: 1160px;
                    margin: 0 auto;
                    border: 2px solid #0D0D0D;
                    border-radius: 20px;
                    & a {
                        color: #0D0D0D;
                    }
    
                    &__items {
                        display: flex;
                        flex-wrap: nowrap;
                        border-bottom: 2px solid #0D0D0D;
                        &:last-of-type { 
                            border-bottom: none;
                        }
                        &__item {
                            &__header {
                                width: 230px;
                                padding: 26px 28px;
                                text-align: center;
                                border-right: 2px solid #0D0D0D;
                                border-bottom: none;

                                
                                &.first {
                                  border-top-left-radius: 18px;  
                                  border-top-right-radius: 0;  
                                  border-right: 2px solid #0D0D0D;
                                }
    
                                &.last {
                                  border-bottom-left-radius: 18px;    
                                }
    
                                &.last.full {
                                  border-bottom-right-radius: 18px;    
                                }
    
                                &.full {
                                    width: 100%;
                                    text-align: left;
                                    border: none;
                                }
    
                                &__text {
                                    font-size: 38px;
                                    line-height: 40px;
                                }
    
                                &__button {
                                    display: inline-block;
                                    position: unset;
                                }
                            }
    
                            &__text {
                                width: calc((100% - 230px) / 2);
                                padding: 28px 28px 30px;
                                &:last-of-type {
                                    padding-top: 30px;
                                }
                                &__title {
                                    font-size: 26px;
                                    line-height: 34px;
                                    color: #0D0D0D;
                                }
                                &__text {
                                    margin-top: 6px;
                                    font-size: 18px;
                                    line-height: 23px;
                                    color: #0D0D0D;
                                }
    
                                &.first {
                                    border-top-right-radius: 18px;  
                                }
      
                                &.last {
                                    border-bottom-right-radius: 18px;    
                                    border-bottom-left-radius: 0;    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
