@import './../../core.scss';

.complex-banner {
    // margin-left: 70px;

    @media screen and (min-width: 1500px) {
        margin-left: 0px;
    }
    & .banner {
        text-align: left;
        position: relative;
        z-index: 13;
        padding: 50px 0 50px;

        &__text {
            align-items: flex-start;
        }

        &-wrap {
            display: flex;
            width: 1200px;
            align-items: center;
            justify-content: flex-start;
            margin-left: 40px;
        }
        @media screen and (min-width: 767px) and (max-width: 1439px) {
            &-wrap {
                // margin-left: 40px;
                width: 1200px;
            }
        }
    }
    &__wrap {
        position: relative;
        & .button {
            text-transform: uppercase;
            font-size: 28px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.01em;
            margin-left: 200px;
        }
        &::after {
            content: "";
            background-image: url("./../../assets/images/blue-spot-small.svg");
            background-repeat: no-repeat;
            width: 563px;
            height: 220px;
            display: block;

            position: absolute;
            z-index: -1;
            top: -68px;
            left: -72px;
            @media screen and (min-width: 1750px) {
                left: -15px;
            }
        }
    }
    @include scale_from_to(1200px, $min-size) {
        margin-left: 0;
        .complex-banner__wrap {
            margin-bottom: 60px;
            &::after {
                display: none;
            }

            & .button { width: 340px; margin-left: 0px; }
        }
        .banner-wrap { flex-direction: column; margin-top: 20px; padding-left: 0px; }
    }
    @include scale_from_to(767px, $min-size) {
        margin-left: -10px;
        .banner-wrap {
            align-items: flex-start;
            justify-content: flex-start;
            width: min-content;
            margin-left: 10px;
        }
    }
}
