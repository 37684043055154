@import "./../../core.scss";

.agenda-list {
  padding: 80px 50px;
  display: grid;
  justify-items: center;
  // align-items: flex-start;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  max-width: $desktop;
  margin: 0 auto;
  grid-column-gap: 60px;

  &__plan {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  &__pic {
    object-fit: contain;
  }
  & img.agenda-list__pic {
    display: inline-block;
    margin-bottom: 20px;
    outline: 3px solid $primary-dark;
    outline-offset: -3px;
    width: 100%;
  }
  @media screen and (min-width: 767px) {
    &__pic {
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    height: min-content;
    text-decoration: none;
    margin-top: 65px;
  }
  &__image {
    width: 20px;
    height: 24px;
    display: block;
    margin-left: 5px;
    fill: #0d0d0d;
  }
  &__link {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 23px;
    text-decoration: none;
    color: $primary-dark;

    &::after {
      content: "";
      display: block;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width 0.7s ease, background-color 0.5s ease;
    }

    &:hover:after {
      width: 100%;
      background: $primary-red;
    }
  }

  @include scale_from_to($smallDesktop, $min-size) {
    grid-template-columns: 1fr;
  }
  @include scale_from_to($smallDesktop, $tablet) {
    padding: 80px 30px;
  }
  @include scale_from_to($tablet, $min-size) {
    padding: 15px;
  }
}
