@import './../../core.scss';

.tc-wrapper {
    max-width: 1098px;
    margin: 0 auto 98px;

    &__append {
        padding: 50px 0 0;
        &__link {
            display: block;
            margin-bottom: 8px;
        }
        &__text {
            margin: 20px 0 0;
        }
        &__text, &__link {
            font-size: 18px;
            line-height: 23px;
            color: #000000;
        }
    }

    &__header{
        margin-top: 50px;
        position: relative;
        text-align: center;
        height: 125px;
        &__title {
            font-size: 66px;
            font-family: Bangers, Arial, Helvetica, sans-serif;
            line-height: 70px;
            min-height: 70px;
            text-align: center;
            letter-spacing: 0.09em;
        
            color: #4FADE4;
            text-shadow: 4px 2px 0px #000000;
        }
    }

    &__container {
        &__block{
            border-bottom: 2px solid #0D0D0D;
        }
        &__title {
            font-size: 30px;
            line-height: 39px;
            font-weight: 500;
            margin: 28px 0 16px;
            color: #0d0d0d;
            &__append {
                margin: 120px 0 0;
            }
        }
        &__subtitle {
            font-size: 18px;
            line-height: 23px;
            color: #0d0d0d;
            font-weight: 500;
            margin-bottom: 16px;
        }
        &__text {
            font-size: 18px;
            line-height: 23px;
            color: #0d0d0d;
            font-weight: 400;
            padding-bottom: 28px;
            &__list {
                display: list-item;
                margin-left: 26px;
            }
            &__dash {
                margin-left: 60px;
                display: block;
                &::before {
                    content: "-";
                    margin-right: 5px;
                }
            }
        }
    }
}

@include scale_from_to($tablet, $min-size) {
    .tc-wrapper {
        padding: 0 18px;
        margin: 0 auto 23px;

        &__append {
            &__text {
                margin: 16px 0 0;
            }
            &__link {
                font-size: 16px;
                line-height: 21px;
            }
        }

        &__header{
            &__title {
                font-size: 42px;
                line-height: 45px;
                min-height: 45px;
    
            }
        }

        &__container {
            &__block{
                border-bottom: 2px solid #0D0D0D;
            }
            &__title {
                font-size: 22px;
                line-height: 29px;
                margin: 22px 0 12px;
                &__append {
                    margin: 60px 0 0;
                }
            }
            &__subtitle {
                margin-bottom: 12px;
            }
            &__text {
                font-size: 16px;
                line-height: 21px;
            }
        }
    } 
}

