@import "./../../core.scss";

.footer {
    text-align: center;
    padding: 100px 0 0;

    &__title {
        font-family: Bangers, serif;
        font-size: remConverter(62);
        line-height: 77px;
        text-align: center;
        letter-spacing: 0.09em;

        color: #B92F29;

        text-shadow: 5px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
        position: relative;

        width: fit-content;
        margin: 0 auto;

        &::after {
            content: "Baltic HoneyBadger 2022";
            text-shadow: 1px -1px 0 #ffc239, 1px -1px #ffc239, -1px 1px #ffc239, 0px -1px #ffc239, -1px 0px #ffc239, 0px 1px #ffc239, 1px 0px #ffc239;

            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &__title-register {
        font-family: Bangers, serif;
        font-size: remConverter(62);
        line-height: 77px;
        text-align: center;
        letter-spacing: 0.09em;

        color: #B92F29;

        text-shadow: 5px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
        position: relative;

        width: fit-content;
        margin: 0 auto;

        &::after {
            content: "Baltic HoneyBadger 2023";
            text-shadow: 1px -1px 0 #ffc239, 1px -1px #ffc239, -1px 1px #ffc239, 0px -1px #ffc239, -1px 0px #ffc239, 0px 1px #ffc239, 1px 0px #ffc239;

            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
    }

    &__wrap {
        gap: 24px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        margin-top: 4rem;

        @media screen and (min-width: 768px) {
            gap: 0;
            row-gap: 32px;
        }
    }

    &__text {
        font-size: 28px;
        line-height: 36px;
        color: $primary-dark;
    }

    &__link {
        padding-left: 28px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 24px 24px;

        text-decoration: none;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;

        cursor: pointer;

        color: $primary-dark;

        @media screen and (min-width: 768px) {
            padding-left: 40px;
            background-size: 36px 36px;
            font-size: 28px;
            line-height: 36px;

            &:after {
                content: "";
                padding: 0 32px;
                background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='3' fill='%230D0D0D'/%3E%3C/svg%3E");
                background-size: 6px 6px;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }

    &__link:last-of-type:after {
        display: none;
    }

    &__link:hover span {
        border-bottom: 1px solid currentColor;
    }

    &__link_email {
        background-image: url('./icon_email.svg');
    }

    &__link_twitter {
        background-image: url('./icon_twitter.svg');
    }

    &__link_youtube {
        background-image: url('./icon_youtube.svg');
    }

    &__link_telegram {
        background-image: url('./icon_telegram.svg');
    }

    &__link_facebook {
        background-image: url('./icon_fb.svg');
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap-reverse;
        margin-top: 34px;
        padding: 23px 48px;
        background: #0D0D0D;
        column-gap: 20px;

        &__copyright, &__terms {
            color: #FFC239;
            font-size: 28px;
            font-weight: 400;
            line-height: 36px;
        }

        &__terms {
            text-decoration: none;
        }
    }

    @include scale_from_to($tablet, $min-size) {
        padding: 40px 0 0;

        &__text {
            font-size: 16px;
        }
        &__title {
            font-size: 42px;
            line-height: 45px;
            letter-spacing: 0.03em;
        }

        &__container, &__title, &__title::after {
            padding-right: 20px;
            padding-left: 20px;
        }

        &__bottom {
            justify-content: center;
            row-gap: 12px;
            margin-top: 30px;

            &__copyright, &__terms {
                font-size: 16px;
                line-height: 21px;
            }
        }
    }
}
