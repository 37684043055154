@import './../../core.scss';

.header {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 53px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;

    position: relative;

    .inner {
        margin: 30px 0;
    }
    .banner {
        padding: 100px 50px 50px;

        @include scale_from_to(1024px, 768px) {
            padding: 0 50px 50px;
        }
    }
    .banner-text {
        height: max-content;
    }

    .button {
        min-width: 250px;
        width: 300px;
        cursor: pointer;
    }

    &__subtitle {
        margin: 20px 0 8px;

        text-align: center;
        font-size: 18px;
        line-height: 22px;
        padding-right: 15px;

        @media screen and (min-width: 768px) {
            margin-top: 98px;
        }

        @media screen and (min-width: 1024px) {
            margin-top: 74px;
            font-size: remConverter(28);
            line-height: 36px;
        }

        @media screen and (min-width: 1200px) {
            margin-top: -16px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 46px;
        line-height: 60px;

        text-shadow: 1px 0 0 #0D0D0D;

        color: $primary-dark;
        position: relative;
        text-align: center;

        margin-top: 10%;
    }
    &__wrap {
        position: relative;
        text-align: center;
        height: 125px;

        svg {
            position: absolute;
            width: 100vw !important;
            height: 100vh !important;
            top: -125px;
            bottom: 0;
            right: -150px;
            z-index: -1;
        }
    }

    &__img {
        position: absolute;
        width: 100vw;
        /* height: 100%; */
        left: -15vw;
        top: -100px;
    }

    &__title {
        font-size: remConverter(98);
        font-family: Bangers, Arial, Helvetica, sans-serif;
        line-height: 104px;
        min-height: 104px;
        text-align: center;
        letter-spacing: 0.09em;

        color: #4FADE4;
        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
        -webkit-text-stroke: 1px #4fade4;
        position: relative;

        &::after {
            content: "Baltic HoneyBadger 2022";
            text-shadow: 12px 8px 0 #0d0d0d, -3px -3px 0 #0d0d0d, 3px -3px #0d0d0d, -3px 3px #0d0d0d, 0px -3px #0d0d0d, -3px 0px #0d0d0d, 0px 3px #0d0d0d, 3px 0px #0d0d0d;

            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__menu {
        @media screen and (min-width: 768px) {
            margin-right: 36px;
        }
    }

    &__badger {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        & div {
            position: absolute;
            z-index: -1;

            top: 6%;
            left: 0;
            width: 100vw;
            height: auto;

            @media screen and (min-width: 768px) {
                top: 80px;
                left: calc(50% - 240px);
                width: 1920px;
                height: auto;
                transform: translateX(-50%);
            }

            @media screen and (min-width: 1200px) {
                top: 20px;
                left: calc(50% - 80px);
            }

            @media screen and (min-width: 1600px) {
                top: -22px;
                left: calc(50% - 80px);
            }
        }
    }

    @include scale_from_to($desktop, $tablet) {
        &__subtitle { text-align: center; }
    }
    @include scale_from_to(1200px, $min-size) {
        &__wrap { height: auto; }
    }
    @include scale_from_to($desktop, $smallDesktop) {
        &__title { font-size: 82px; line-height: 87px; }
        &__subtitle { font-size: 28px; line-height: 36px;}
    }
    @include scale_from_to($smallDesktop, $tablet) {
        background-position: top;
        background-size: contain;
        margin-top: 0;
        &__title { font-size: 78px; line-height: 83px; }
        &__subtitle {  }
    }
    @include scale_from_to($tablet, $min-size) {
        height: auto;
        //padding: 0 15px;
        background-size: contain;
        margin-top: 0;
        & .inner { margin: 15px 0; }

        &__img { display: none; }

        &__subtitle {

        }
        &__title{
            font-size: 42px;
            line-height: 45px;
            letter-spacing: 0.03em;
            &::after {
                text-shadow: 1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;
            }
        }
        &-title {
            padding-right: 15px;
            &__wrap { height: auto; }
        }
        &__img { display: none; }
        &__text {
            margin: 0;
            padding-top: 28px;
            text-align: center;
            font-size: 28px;
            line-height: 32px;
            padding-right: 15px;
        }
        & .button { width: 340px; margin-top: 20px; margin-right: 15px; }
        &__wrap {
            margin-bottom: 30px; padding-right: 15px;
        }
    }
    @include scale_from_to($mobile, $min-size) {
        padding-right: 0;
    }
}
