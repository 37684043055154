@import "./../../core.scss";
.light {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    max-width: $desktop;
    margin: 0 auto;

    &__img {
        width: 244px;
        height: 428px;
        position: static;
    }
    &__text {
        font-weight: 500;
        font-size: 50px;
        line-height: 65px;

        color: #0D0D0D;
        margin-left: 60px;
    }

    @include scale_from_to($tablet, $min-size) {
        &__text { font-size: 38px; line-height: 36px; text-align: center; margin: 60px 15px; }
        &__img {
            width: 182px;
            height: 322px;
            position: absolute;

            z-index: -1;
            right: -70px;
            top: 200px;
        }
    }
}
