@import './../../style.scss';

.conference-tickets {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    position: relative;
    min-height: calc(100vh - 250px);

    &__container {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-family: Bangers, Arial, sans-serif;
        text-align: center;
        width: fit-content;
        margin-top: 26px;
        font-weight: 400;
        font-size: 42px;
        line-height: 45px;
        color: #4fade4;
        text-shadow: 4px 4px 0 #0d0d0d, -1px -1px 0 #0d0d0d, 1px -1px #0d0d0d,
            -1px 1px #0d0d0d, 0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d,
            1px 0px #0d0d0d;
        -webkit-text-stroke: 1px #4fade4;
        position: relative;

        &::after {
            content: "2-day BH2022 conference ticket";
            text-shadow: 1px -1px 0 #0d0d0d, 1px -1px #0d0d0d, -1px 1px #0d0d0d,
            0px -1px #0d0d0d, -1px 0px #0d0d0d, 0px 1px #0d0d0d, 1px 0px #0d0d0d;

            position: absolute;
            left: 0;
            top: 0;
            width: fit-content;
        }
    }

    &__subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: #0d0d0d;
        margin: 14px auto 27px;
    }

    &__card {
        max-width: 350px;
        width: 100%;
        box-sizing: border-box;
    }

    &__honeybadger {
        background-image: url('./../../assets/images/bg-mobile.png');
        height: 300px;
        margin-top: 0;

        max-height: max-content;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__disclaimer {
        margin-top: 10px;
        text-align: center;
        max-width: 652px;
        width: 100%;
    }

    .card {
        padding: 0 18px 9px;
        width: auto;
        min-height: 274px;
        height: fit-content;
        background: #0D0D0D;
        border: 2px solid #0D0D0D;
        border-radius: 25px;
        box-sizing: border-box;
        margin: 0;

        &__header {
            position: relative;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column-reverse;
            height: min-content;
            width: 100%;
            padding-top: 72px;
        }
        &__img {
            position: absolute;
            top: 0;
            right: -20px;
        }
        &__about-price {
            font-weight: 700;
            //font-size: 24px;
            line-height: 31px;

            font-size: 18px;

            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        &__price {
            font-weight: 700;
            font-size: 52px;
            line-height: 52px;
            color: #FFC239;
            margin-right: 6px;
            &__tail {
                font-size: 26px;
            }
        }
        &__elem {
            position: relative;
            list-style-type: none;
            display: flex;
            padding: 2px 0;

            margin: 0;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: #FFFFFF;
            padding-left: 28px;

            &__link {
                color: #FFC239;
            }

            &::before {
                position: absolute;
                top: 3px;
                left: 0;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7.2' y='7.7' width='5.6' height='5.6' rx='2.8' fill='%23FFC239' stroke='%23000' stroke-width='.4'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                display: block;
                padding: 0;
                margin: 0;
            }
        }
        &__body {
            padding: 0;
            //padding: 9px 0 0;
        }
        &__list {
            margin-top: 24px;
        }
        &__buttons {
            margin-top: 38px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            & .button {
                max-width: 310px;
                background: transparent;
                color: #FFC239;
                font-style: normal;

                width: 100%;
                font-size: 22px;
                line-height: 29px;
                margin: 9px auto;

                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .button.button--primary {
                background: $primary-red;
                font-style: normal;
                color: $primary-yellow;
            }

            & .button:hover {
                background: $primary-blue;
                font-style: italic;
                color: $primary-dark;
                border-color: $primary-blue;
            }
        }
    }

    @media screen and (min-width: 768px) {
        margin-top: 50px;
        min-height: calc(100vh - 390px - 50px);

        &__disclaimer {
            font-family: "DM Sans", serif;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #0d0d0d;
            margin: 7px auto;
            position: relative;
            align-self: start;
            text-align: left;
            padding-left: 70px;
        }
        &__title {
            font-size: 66px;
            letter-spacing: 2px;
            line-height: 70px;
            margin-top: 0;

            &::after {
                text-shadow: 3px 3px 0 #0d0d0d, -3px -3px 0 #0d0d0d, 3px -3px #0d0d0d,
                -3px 3px #0d0d0d, 0px -3px #0d0d0d, -3px 0px #0d0d0d, 0px 3px #0d0d0d,
                3px 0px #0d0d0d;
            }
        }
        &__subtitle {
            font-size: 20px;
            line-height: 26px;
            margin-top: 10px;
            margin-bottom: 36px;
        }
        &__honeybadger {
            background: url('./../../assets/images/bg-1920.jpg') no-repeat center center;
            background-size: cover;
            height: 630px;
            position: absolute;
            top: 279px;
            z-index: -1;
        }
        &__card {
            max-width: 652px;
        }
        .card {
            width: 100%;
            padding: 12px 18px 24px;

            &__price__tail{
                font-size: 52px;
            }
            &__about-price {
                align-items: flex-end;
                font-size: 24px;
            }
            &__header {
                padding-top: 15px;
                padding-left: 12px;
                flex-direction: row;
            }
            &__body {
                padding: 0;
                padding-top: 16px;
            }
            &__img {
                right: 0;
                margin-right: 0;
            }
            &__list {
                margin-top: 0;
                margin-left: 14px;
            }
            &__buttons {
                margin-top: 28px;
                flex-direction: row;

                .button {
                    width: 100%;
                    max-width: 278px;
                    letter-spacing: 0.01em;
                    padding: 8px 0;
                }
            }
        }
    }
}
