@import './../../core.scss';

.speakers {
    .banner {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 767px) and (min-width: 0px) {
            .banner__text { font-size: 24px; }
        }
    }
    @media screen and (max-width: 767px) and (min-width: 0px) {
        .comic-text { font-size: 40px; }
    }

    .comic-text--banner {

        @media screen and (max-width: 1024px) and (min-width: 768px) {
            &::before {
                left:0;
            }
        }
        @media screen and (max-width: 767px) and (min-width: 0px) {
            &::before {
                left: 50px;
            }
            .banner__text { letter-spacing: -0.02em; }
        }

    }
    .persons {
        display: flex;
        align-items: center;
        justify-content: center;

        &__wrap {
            max-width: $desktop;
            justify-items: center;
            width: 100%;
        }
    }
}
