@import './../../core.scss';

.multi-toggle-container {
    padding: 5px 6px;
    border: 1px solid #000000;
    border-radius: 24px;
    height: 48px;
    line-height: 48px;
    margin: 60px auto 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: static;
    transition: all 0.1s ease-in-out;

    &.fixed {
      position: sticky;
      top: 90px;
      background: $primary-yellow;
      z-index: 10;
      max-width: 1198px;
      width: 100%;
      transition: all 0.1s ease-in-out;
    }

    .active-layer {
      position: absolute;
      transition: 0.4s ease-in-out;
      margin: 3px;
      margin-left: calc(66.66% + 3px);
      border-radius: 5px;
      height: calc(100% - 6px);
      background-color: white;
      width: calc(100% / 3 - 6px);
    }
}
.select { display: none; }

  .multi-toggle-item {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: rgba(13, 13, 13, 0.8);
    padding: 8px 14px;
    z-index: 2;
    cursor: pointer;
    position: static;
    text-decoration: none;
    border: 2px solid transparent;

    @media (min-width: 768px) {
      &:hover {
        background: transparent;
        border: 2px solid #000000;
        box-shadow: 2px 1px 0 #000000;
        border-radius: 20px;
      }
    }

    &.active {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      cursor: pointer;
      background-color: transparent;

      border: 2px solid #000000;
      box-sizing: border-box;
      box-shadow: 2px 1px 0 #000000;
      border-radius: 20px;
      width: fit-content;

      top: 0;
      z-index: 1;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;

      @media (min-width: 768px) {
        &:hover {
          background: $primary-dark;
          color: $primary-yellow;
        }
      }
    }
  }

  @include scale_from_to(1023px, $min-size) {
    .multi-toggle-container { display: none; }
    .select {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      line-height: 23px;
      position: relative;
      margin: 0px auto 40px;

      color: #2C2C2C;
      &-option {
        border: 1px solid #2C2C2C;
        box-sizing: border-box;
        border-radius: 30px;
        min-width: 360px;
        padding: 10px 18px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          content: "";
          background: url('./../../assets/images/chevron-down.svg');
          width: 16px;
          height: 16px;
          display: block;
        }

      }
      &-options {
        position: absolute;
        z-index: 100;
        display: block;
        top: 44px;
        min-width: 360px;
        background: #FFC239;
        border: 1px solid #0D0D0D;
        box-sizing: border-box;
        border-radius: 25px;
        padding: 16px;
        margin-top: 10px;

        visibility: hidden;

        opacity: 0;

        transition: transform 100ms ease-in-out, opacity 100ms ease-in-out, visibility 0ms linear 100ms;

        pointer-events: none;
        -webkit-overflow-scrolling: touch;
        transition-duration: 75ms;

        animation-duration: 75ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;

        animation-name: content_leaving;
      }
      &-options.visible {
        visibility: visible;
        /* In Edge (pre chromium), a stacking context is formed for opacity less then 1, and then its removed for 1.
           It causes a rendering flicker that is visible when css transition is applied. */
        opacity: 0.9999;

        transition-delay: 0ms;

        pointer-events: auto;

        animation-name: content_entering;
      }

      &-item {
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        cursor: pointer;

        color: rgba(13, 13, 13, 0.8);
        padding: 8px 14px;
        border-bottom: 1px solid rgba(13, 13, 13, 0.12);

        &:last-child {
          border-bottom: none;
        }
      }
    }
    .multi-toggle-container {
      flex-direction: column;
      border: none;
    }
  }

@keyframes content_entering {
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes content_leaving {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}
