$max_size: 1920px;
$desktop: 1440px;
$smallDesktop: 1200px;
$bigTablet: 1024px;
$tablet: 767px;
$mobile: 429px;
$min_size: 0px;

$nav: 120px;
$title: 260px;
$height: 75vh;
