@import './../../core.scss';

.plans {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: $desktop;
    padding: 100px 50px 80px;
    margin: 0 auto;

    @include scale_from_to($tablet, $min-size) {
        flex-direction: column;
        height: fit-content;
        padding: 15px;
        align-items: center;
    }
    @include scale_from_to($smallDesktop, $tablet) {
        justify-content: space-between;
        padding: 80px 30px;
        flex-wrap: wrap;
        // margin-right: 50px;
    }


}

.block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 30%;

    @include scale_from_to(1024px, $tablet) {
        width: 45%;
        margin: 10px;
    }

    &__image {
        width: 424px;
        max-width: 424px;
        height: 328px;
        outline: 3px solid $primary-dark;
        outline-offset: -3px;
        object-fit: cover;

        @media screen and (min-width: 1700px) {
            width: 370px;
        }
    }

    &__title {
        font-weight: 500;
        font-size: 30px;
        line-height: 39px;
        margin: 10px 0px;

        color: $primary-dark;
    }
    &__text {
        font-size: 18px;
        line-height: 23px;
        margin: 10px 0px;

        color: $primary-dark;
    }
    &__link {
        font-size: 18px;
        line-height: 23px;

        color: $primary-dark;

        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;

        & .agenda-list__link { text-transform: none; }

        &::after {
            content: "";
            // background-image: url("./../../assets/images/arrow.svg");
            // background-repeat: no-repeat;

            width: 24px;
            height: 24px;

            margin-top: 10px;
            margin-left: 10px;
        }
    }

    @include scale_from_to(1439px, 1201px) {
        &__image { width: auto; max-width: 370px; }
    }

    @include scale_from_to(1200px, $tablet) {
        &__image {   width: auto; max-width: 332px;  }
    }

    @include scale_from_to($tablet, $min-size) {
        width: min-content;
        margin: 20px 0;

        &__image {
            // width: 100%;
            width: 325px;
        }
        &__title { font-size: 22px; margin: 0px; }

        &__link { font-size: 16px; }

        &__text { font-size: 16px; margin: 0px 0px 16px }
    }
}


